<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        flat
        style="position: sticky; top: 0px; z-index: 1;"
      >
        <v-card-title
          v-if="actualTab"
          class="font-weight-light"
        >
          {{ $t('actions|add_to') }}  {{ actualTab.text }} {{ $t('mixed|categories') }}
        </v-card-title>
        <v-card-title
          v-else
          class="font-weight-light"
        >
          {{ $t('actions|add_categories') }}
        </v-card-title>
        <v-spacer />
        <v-icon
          size="24"
          class="mr-3"
          @click="closeAndReset()"
        >
          mdi-close
        </v-icon>
      </v-toolbar>
      <div
        v-for="(input, index) in categoryTexts"
        :key="`categoryInput-${index}`"
      >
        <v-text-field
          v-model="input.value"
          outlined
          dense
          :label="$t('actions|add_new_category')"
          class="mx-10 my-1"
          :rules="[(v) => !!v || $t('expressions|text_required_field_cannot_be_empty')]"
          :append-icon="input.value ? 'mdi-close' : ''"
          @click:append="clearInput(index)"
        >
          <v-icon
            v-if="categoryTexts.length > 1"
            slot="append-outer"
            size="21"
            @click="removeField(index, categoryTexts)"
          >
            mdi-trash-can
          </v-icon>
        </v-text-field>
      </div>
      <v-card-actions>
        <v-btn
          outlined
          rounded
          small
          color="primary"
          class="mb-3"
          @click="addField(categoryTexts)"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          {{ $t('actions|add_new_field') }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="mb-3"
          color="primary"
          rounded
          small
          dense
          min-width="110"
          :loading="loading"
          :disabled="disabledRule"
          @click="submitCategory(); closeAndReset();"
        >
          <v-icon
            small
            class="mr-1 ml-n2"
          >
            mdi-content-save-outline
          </v-icon>
          {{ $t('actions|save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      disabledRule: true,
      categoryTexts: [],
      actualTab: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    })
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.closeAndReset()
      }
    },
    categoryTexts: {
      handler (value) {
        if (value.length) {
          value.forEach(text => {
            if (text.value === '') this.disabledRule = true
            else this.disabledRule = false
          })
        } else {
          this.disabledRule = false
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      updateCaseForm: 'caseForms/updateCaseForm',
      addCaseModelCategories: 'account/addCaseModelCategories',
      addCompanyCaseModelsCategories: 'company/addCompanyCaseModelsCategories',
      addGroupCaseModelsCategories: 'company/addGroupCaseModelsCategories',
      updateSettings: 'settings/updateSettings'
    }),
    async submitCategory () {
      this.loading = true
      if (this.actualTab.isMyTab) {
        const payload = {
          myCaseModelsCategories: this.categoryTexts
        }
        try {
          await this.addCaseModelCategories(payload)
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (this.actualTab.isCompanyTab) {
        const _id = this.company._id
        const payload = {
          companyCaseModelsCategories: this.categoryTexts
        }
        try {
          await this.addCompanyCaseModelsCategories({ _id, payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
          this.resetDialogAfterSave()
        }
      } else if (this.actualTab.isGroupTab) {
        const _id = this.actualTab.group._id
        const payload = {
          groupCaseModelsCategories: this.categoryTexts
        }
        try {
          await this.addGroupCaseModelsCategories({ _id, payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
          this.resetDialogAfterSave()
        }
      } else if (this.actualTab.isLavviraTab) {
        const payload = {
          lavviraCaseModelsDBContent: true,
          lavviraCaseModelsCategories: this.categoryTexts
        }
        try {
          await this.updateSettings({ payload })
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
          this.resetDialogAfterSave()
        }
      }
    },
    addField (fieldType) {
      fieldType.push({ value: '' })
    },
    removeField (index, fieldType) {
      fieldType.splice(index, 1)
    },
    closeAndReset () {
      this.dialog = false
      this.categoryTexts = []
      this.actualTab = null
      this.loading = false
    },
    onAddCaseModelsCategoriesEvent (actualTab) {
      this.actualTab = actualTab
      this.addField(this.categoryTexts)
      this.dialog = true
    },
    clearInput (index) {
      this.categoryTexts[index].value = ''
    },
    resetDialogAfterSave () {
      this.dialog = false
      this.loading = false
      this.disabledRule = true
      this.categoryTexts = []
      this.actualTab = null
    }
  }
}

</script>

<style>
</style>
